<template>
    <input type="hidden" id="linknya" v-model="linknya">
    <input type="hidden" id="paramkodedi" value="">
    <div id="myInfoLayer" class="sidenavkanan p-0">
        <div class="card card-warning shadow-none">
            <div class="card-header" style="background-color: #28a128 !important;color: #ffffff;">
                <h3 class="card-title text-white">Info</h3>
                <!-- /.card-tools -->
                <div class="card-toolbar">
                    <button type="button" class="btn btn-warning float-right" @click="closeNavInfo()"><i
                            class="fa fa-times"></i></button>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body" style="color: black !important;min-height: 95vh;width: 90%; overflow: auto;"
                id="Info_Adit">
            </div>
        </div>
    </div>
    <div id="mySidenavTanggal" class="sidenav p-0">
        <div class="card card-warning shadow-none">
            <div class="card-header" style="background-color: #28a128 !important;color: #ffffff;">
                <h3 class="card-title text-white">Pilihan Tanggal</h3>
                <!-- /.card-tools -->
                <div class="card-toolbar">
                    <button type="button" class="btn btn-warning float-right" @click="tutupmodalTanggal()"><i
                            class="fa fa-times"></i></button>
                </div>
            </div>
            <div class="card-body">
                <div class="row px-2">
                    <div class="col-sm-12">
                        <label>Range Tanggal</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                            </div>
                            <input type="date" class="form-control form-control-sm" id="tanggal" name="tanggal"
                                placeholder="Tanggal" v-model="tanggal">
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                            </div>
                            <input type="date" class="form-control form-control-sm" id="sdtanggal" name="sdtanggal"
                                placeholder="Tanggal" v-model="sdtanggal">
                        </div>
                        <center>
                            <button class="btn btn-warning btn-sm text-white" @click="awal()">Terapkan Tanggal</button>
                        </center>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="mySidenavLayer" class="sidenav p-0">
        <div class="card card-warning shadow-none">
            <div class="card-header" style="background-color: #28a128 !important;color: #ffffff;">
                <h3 class="card-title text-white">Layer</h3>
                <!-- /.card-tools -->
                <div class="card-toolbar">
                    <button type="button" class="btn btn-warning float-right" @click="closeNavLayer()"><i
                            class="fa fa-times"></i></button>
                </div>
            </div>
            <div class="card-body">
                <h6><b id="judultransparant">Transparansi Layer</b></h6>
                <input id="opacity-input" type="range" min="0" max="1" step="0.01" value="0.75" style="width: 100%;"
                    class="slider" /><span id="opacity-output"></span>
            </div>
            <!-- /.card-header -->
            <div class="card-body" style="color: black !important;min-height: 80vh;">
                <h6><b id="daftarlayer">Daftar Layer</b></h6>
                <div class="card collapsed-card" v-for="(layer, urutlayer) in datapeta" :key="urutlayer">
                    <div class="card-header" style="font-size: smaller;cursor: pointer;" data-card-widget="collapse">
                        <h5 class="card-title" style="font-size: smaller;cursor: pointer;" data-card-widget="collapse">
                            {{ layer.nama }}</h5>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool text-white" data-card-widget="collapse">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="custom-control custom-switch custom-switch-on-primary"
                            v-for="(itemlayer, urutitem) in layer.children" :key="urutitem">
                            <input type="checkbox"
                                :checked="itemlayer.id == 'layer|22|parastapainnovationpulau-GetdataPulau?adi=parastapa|1|#c9e7f2|#000000|1|1|1|1||1|1|0|1|hatch|2'"
                                class="custom-control-input" :id="itemlayer.id"
                                @click="tampillayer(itemlayer.id)"><label class="custom-control-label"
                                :for="itemlayer.id" style="font-size: smaller;">{{
                                    itemlayer.text }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Top />
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/disdag.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">PETA</span>
                <span v-else>PETA</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-warning">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">WEB GIS</span>
                    <span v-else>WEB GIS</span>
                </li>
            </ol>
        </div>
    </div>
    <div class="container-fluid" style="position: absolute;">
        <div class="card shadow-none" style="background-color: #28a128;">
            <div class="card-header" style="background-color: #28a128;">
                <h5 class="card-title text-white">WEB GIS</h5>

                <div class="card-tools">
                    <button type="button" class="btn btn-tool text-white" data-toggle="tooltip" data-placement="left"
                        title="Layer Peta" @click="openNavLayer()">
                        <i class="far fa-clone"></i>
                    </button>
                    <button type="button" class="btn btn-tool text-white" data-toggle="tooltip" data-placement="left"
                        title="Pengaturan Peta">
                        <i class="fas fa-cogs"></i>
                    </button>
                    <button type="button" class="btn btn-tool text-white" data-toggle="tooltip" data-placement="left"
                        title="Pencarian Lokasi Peta">
                        <i class="fas fa-search"></i>
                    </button>
                    <button type="button" class="btn btn-tool text-white" data-card-widget="collapse"
                        data-toggle="tooltip" data-placement="left" title="Show / hide">
                        <i class="fas fa-minus"></i>
                    </button>
                    <button type="button" id="bukabawah" style="display: none;" class="btn btn-tool text-white"
                        data-toggle="tooltip" data-placement="left" title="Buka Bawah" @click="openNavInfoBawah()">
                        <i class="fas fa-cogs"></i>
                    </button>
                </div>
            </div>
            <!-- /.card-header -->
        </div>
    </div>
    <iframe :src="sourcenya" class="container-fluid py-4" style="min-height: 100vh;background-color: #ffffff !important"
        frameborder="0" id="maps" scrolling="no" @load="loadpeta()"></iframe>
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import vSelect from 'vue-select';
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Top, Footer, swal, vSelect, LineHujanRE: VueApexCharts
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            halamanloading: true,
            tanggal: Date.now(),
            sdtanggal: Date.now(),
            tahun: new Date().getFullYear(),
            linknya: process.env.VUE_APP_URL_API,
            sourcenya: '',
            sourcenyamapdetails: '',
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            datapesan: '',
            dataisi: [],
            datalist: [],
            datadetail: [],
            datadetailtab2: [],
            datamaster: [],
            datapeta: [],
            dataklimatologi: [],
            dataet0: [],
            carikata: '',
            carikata2: '',
            carikatatab2: '',
            luas: 0,
            debit: 0,
            kondisi: 0,
            JumlahTotalIP3A: 0,
            JumlahTotalGP3A: 0,
            JumlahTotalP3A: 0,
            kewenangan: [],
            datakewenangan: [],
            defaultSelectedkewenangan: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedkewenangan: '',
            kodeDI: [],
            datakodeDI: [],
            defaultSelectedkodeDI: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedkodeDI: '',
            balai: [],
            databalai: [],
            defaultSelectedbalai: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedbalai: '',
            tampilj2a098002: '',
            IP3A: 0,
            IP3ABE: 0,
            IP3ABB: 0,
            IP3ASB: 0,
            IP3AB: 0,
            IP3AM: 0,

            GP3A: 0,
            GP3ABE: 0,
            GP3ABB: 0,
            GP3ASB: 0,
            GP3AB: 0,
            GP3AM: 0,

            P3A: 0,
            P3ABE: 0,
            P3ABB: 0,
            P3ASB: 0,
            P3AB: 0,
            P3AM: 0,

            pberlembaga: 0,
            ptakberlembaga: 0,
            TotalLaki2PengurusP3A: 0,
            TotalPerempuanPengurusP3A: 0,
            TotalJmlPengurusP3A: 0,
            TotalJmlAnggotaP3A: 0,
            TotalJmlTPM: 0,
            showColumnKode: false,
            showColumnLuas: false,
            showColumnBalai: false,
            showColumnKewenangan: false,
            showColumnNamaDI: false,
            showColumnAkta: false,
            showColumnSK: false,
            showColumnIP3A: false,
            showColumnGP3A: false,
            showColumnIndukWilayah: false,
            showColumnIndukIrigasi: false,
            colspan1: 8,

            totalJanuari1: 0,
            totalJanuari2: 0,
            totalJanuari: 0,
            rataJanuari1: 0,
            rataJanuari2: 0,
            maxJanuari1: 0,
            maxJanuari2: 0,
            minJanuari1: 0,
            minJanuari2: 0,
            shortJanuari1: [],
            shortJanuari2: [],

            totalFebruari1: 0,
            totalFebruari2: 0,
            totalFebruari: 0,
            rataFebruari1: 0,
            rataFebruari2: 0,
            maxFebruari1: 0,
            maxFebruari2: 0,
            minFebruari1: 0,
            minFebruari2: 0,
            shortFebruari1: [],
            shortFebruari2: [],

            totalMaret1: 0,
            totalMaret2: 0,
            totalMaret: 0,
            rataMaret1: 0,
            rataMaret2: 0,
            maxMaret1: 0,
            maxMaret2: 0,
            minMaret1: 0,
            minMaret2: 0,
            shortMaret1: [],
            shortMaret2: [],

            totalApril1: 0,
            totalApril2: 0,
            totalApril: 0,
            rataApril1: 0,
            rataApril2: 0,
            maxApril1: 0,
            maxApril2: 0,
            minApril1: 0,
            minApril2: 0,
            shortApril1: [],
            shortApril2: [],

            totalMei1: 0,
            totalMei2: 0,
            totalMei: 0,
            rataMei1: 0,
            rataMei2: 0,
            maxMei1: 0,
            maxMei2: 0,
            minMei1: 0,
            minMei2: 0,
            shortMei1: [],
            shortMei2: [],

            totalJuni1: 0,
            totalJuni2: 0,
            totalJuni: 0,
            rataJuni1: 0,
            rataJuni2: 0,
            maxJuni1: 0,
            maxJuni2: 0,
            minJuni1: 0,
            minJuni2: 0,
            shortJuni1: [],
            shortJuni2: [],

            totalJuli1: 0,
            totalJuli2: 0,
            totalJuli: 0,
            rataJuli1: 0,
            rataJuli2: 0,
            maxJuli1: 0,
            maxJuli2: 0,
            minJuli1: 0,
            minJuli2: 0,
            shortJuli1: [],
            shortJuli2: [],

            totalAgustus1: 0,
            totalAgustus2: 0,
            totalAgustus: 0,
            rataAgustus1: 0,
            rataAgustus2: 0,
            maxAgustus1: 0,
            maxAgustus2: 0,
            minAgustus1: 0,
            minAgustus2: 0,
            shortAgustus1: [],
            shortAgustus2: [],

            totalSeptember1: 0,
            totalSeptember2: 0,
            totalSeptember: 0,
            rataSeptember1: 0,
            rataSeptember2: 0,
            maxSeptember1: 0,
            maxSeptember2: 0,
            minSeptember1: 0,
            minSeptember2: 0,
            shortSeptember1: [],
            shortSeptember2: [],

            totalOktober1: 0,
            totalOktober2: 0,
            totalOktober: 0,
            rataOktober1: 0,
            rataOktober2: 0,
            maxOktober1: 0,
            maxOktober2: 0,
            minOktober1: 0,
            minOktober2: 0,
            shortOktober1: [],
            shortOktober2: [],

            totalNovember1: 0,
            totalNovember2: 0,
            totalNovember: 0,
            rataNovember1: 0,
            rataNovember2: 0,
            maxNovember1: 0,
            maxNovember2: 0,
            minNovember1: 0,
            minNovember2: 0,
            shortNovember1: [],
            shortNovember2: [],

            totalDesember1: 0,
            totalDesember2: 0,
            totalDesember: 0,
            rataDesember1: 0,
            rataDesember2: 0,
            maxDesember1: 0,
            maxDesember2: 0,
            minDesember1: 0,
            minDesember2: 0,
            shortDesember1: [],
            shortDesember2: [],

            rata2HujanHarian: [],
            Jumlahdatarata2HujanHarian: 0,
            rata2HujanHarianPerHari: [],
            rata2HujanHarianPerHariGrafik: [],

            Q80: [],
            Q50: [],
            RE80: [],
            RE80Hari: [],
            RE80HariGrafik: [],

            RE50: [],
            RE50Hari: [],
            RE50HariGrafik: [],

            series: [],

            chartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    toolbar: {
                        show: true
                    }
                },
                colors: ['#77B6EA', '#ADFF2F', '#00CED1'],
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val.toFixed(2).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    },
                    offsetY: -5,
                    style: {
                        fontSize: '8px',
                        colors: ['#5c6165', '#ff7f00'],
                    }
                },
                stroke: {
                    curve: 'smooth'
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    categories: ['Jan1', 'Jan2', 'Feb1', 'Feb2', 'Mar1', 'Mar2', 'Apr', 'Apr2', 'Mei1', 'Mei2', 'Juni1', 'Juni2', 'Juli1', 'Juli2', 'Ags1', 'Ags2', 'Sep1', 'Sep2', 'Okt1', 'Okt2', 'Nov1', 'Nov2', 'Des1', 'Des2'],
                    title: {
                        text: 'Bulan'
                    }
                },
                yaxis: {
                    title: {
                        text: 'Curah Hujan (mm/hari)'
                    },
                    labels: {
                        formatter: function (value) {
                            return value.toFixed(2).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        }
                    },
                },
                legend: {
                    position: 'bottom',
                    show: true,
                }
            },

            tinggi: [],
            koreksi: [],
            ukmhr: [],
            fu: [],

            suhu: [],
            ea: [],
            w: [],
            satuminw: [],
            ft: [],

            acuanra: [],
            ra1: [],
            ra2: [],
            ra: [],
        };
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datadetail
                : this.datadetail.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },

        filteredKataData2() {
            const filteredWorkers = this.carikata2 === ""
                ? this.datalist
                : this.datalist.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata2.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData2() {
            var jumlah = 0;
            try {
                if (this.filteredKataData2 === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData2.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },

        filteredKataDataTab2() {
            const filteredWorkers = this.carikatatab2 === ""
                ? this.datadetailtab2
                : this.datadetailtab2.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikatatab2.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataDataTab2() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataTab2 === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataTab2.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        showkode() {
            this.showColumnKode = !this.showColumnKode;
            if (this.showColumnKode == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showluas() {
            this.showColumnLuas = !this.showColumnLuas;
            if (this.showColumnLuas == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showindukwilayah() {
            this.showColumnIndukWilayah = !this.showColumnIndukWilayah;
            if (this.showColumnIndukWilayah == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showindukirigasi() {
            this.showColumnIndukIrigasi = !this.showColumnIndukIrigasi;
            if (this.showColumnIndukIrigasi == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showakta() {
            this.showColumnAkta = !this.showColumnAkta;
            if (this.showColumnAkta == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showsk() {
            this.showColumnSK = !this.showColumnSK;
            if (this.showColumnSK == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showbalai() {
            this.showColumnBalai = !this.showColumnBalai;
            if (this.showColumnBalai == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showkewenangan() {
            this.showColumnKewenangan = !this.showColumnKewenangan;
            if (this.showColumnKewenangan == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showNamaDI() {
            this.showColumnNamaDI = !this.showColumnNamaDI;
            if (this.showColumnNamaDI == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showIP3A() {
            this.showColumnIP3A = !this.showColumnIP3A;
            if (this.showColumnIP3A == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showGP3A() {
            this.showColumnGP3A = !this.showColumnGP3A;
            if (this.showColumnGP3A == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        prosesdataepaksi() {
            //alert(document.getElementById("isicekepaksi").innerHTML);
            var id = document.getElementById("isicekepaksi").innerHTML;
            //alert(id);

            var parameter = 'epaksi**' + this.selectedkewenangan + '**' + this.selectedkodeDI + '**' + this.selectedbalai + '**' + this.tanggal + '**' + this.sdtanggal + '**';
            const message = this.linknya + '|||' + parameter + '|||' + document.getElementById("cekepaksi").checked + '&&' + id;
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/map.html';
            iframe.contentWindow.postMessage(message, "*");
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        bukamodalTanggal() {
            this.closeNavLayer();
            if (document.getElementById("mySidenavTanggal").style.width == "350px") {
                document.getElementById("mySidenavTanggal").style.width = "0";
            } else {
                document.getElementById("mySidenavTanggal").style.width = "350px";
            }
        },
        tutupmodalTanggal() {
            document.getElementById("mySidenavTanggal").style.width = "0";
        },
        tampillayer(id) {
            //alert(id);
            //alert(document.getElementById(id).checked);
            var parameter = 'layer**' + this.selectedkewenangan + '**' + this.selectedkodeDI + '**' + this.selectedbalai + '**' + this.tanggal + '**' + this.sdtanggal + '**';
            const message = this.linknya + '|||' + parameter + '|||' + document.getElementById(id).checked + '|' + id;
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/map.html';
            iframe.contentWindow.postMessage(message, "*");
        },
        awal() {
            //alert(this.tanggal);
            //alert(this.sdtanggal);
            var tanggal = new Date(this.tanggal);
            var sdtanggal = new Date(this.sdtanggal);
            if (tanggal > sdtanggal) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Tanggal Awal Tidak Boleh Lebih Besar..',
                });
                $('.swal2-container').css("z-index", '999999');
                return false;
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            mainAPI.get("parastapainnovation_di_imc-GetDataDI?random=" + random + "&kewenangan=" + this.selectedkewenangan + "&balai=" + this.selectedbalai + "&kodeDI=" + this.selectedkodeDI + "&tanggal=" + this.tanggal + "&sdtanggal=" + this.sdtanggal).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        //this.awal();
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.luas = Response.data.content.data[0].luasha;
                        this.debit = Response.data.content.data[0].ketersediaan;
                        this.kondisi = Response.data.content.data[0].kondisipersen;
                        this.JumlahTotalGP3A = Response.data.content.data[0].JumlahTotalGP3A;
                        this.JumlahTotalIP3A = Response.data.content.data[0].JumlahTotalIP3A;
                        this.JumlahTotalP3A = Response.data.content.data[0].JumlahTotalP3A;
                    }
                    else {
                        this.datamaster = [];
                        this.luas = 0;
                        this.debit = 0;
                        this.kondisi = 0;
                        this.JumlahTotalP3A = 0;
                    }



                }
            ).catch(error => {
                //console.log(error);
                //this.ClearlocalStorage();
                this.halamanloading = false;
            });

            var parameter = 'layer**' + this.selectedkewenangan + '**' + this.selectedkodeDI + '**' + this.selectedbalai + '**' + this.tanggal + '**' + this.sdtanggal + '**';
            const message = this.linknya + '|||' + parameter + '|||true|' + 'layer|22|parastapainnovationpulau-GetdataPulau?adi=parastapa|1|#c9e7f2|#000000|1|1|1|1||1|1|0|1|hatch|2';
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/map.html';
            iframe.contentWindow.postMessage(message, "*");

        },
        rubah() {
            var parameter = 'layer**' + this.selectedkewenangan + '**' + this.selectedkodeDI + '**' + this.selectedbalai + '**' + this.tanggal + '**' + this.sdtanggal + '**';
            document.getElementById('layer|20|parastapainnovationbendung-GetdataBendung?adi=parastapa|2|#00bfff|#000000|1|1|1|1|20241212152213_99018bb44d8b182ff31b89259420f8e3.png|1|1|0|12|hatch|2').checked = true;
            const message = this.linknya + '|||' + parameter + '|||true|' + 'layer|20|parastapainnovationbendung-GetdataBendung?adi=parastapa|2|#00bfff|#000000|1|1|1|1|20241212152213_99018bb44d8b182ff31b89259420f8e3.png|1|1|0|12|hatch|2';
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/map.html';
            iframe.contentWindow.postMessage(message, "*");

        },
        kediterpilih(kodedi) {
            this.closeNavInfoBawah();
            this.closeNavInfo();
            this.closeNavLayer();
            document.getElementById('layer|20|parastapainnovationbendung-GetdataBendung?adi=parastapa|2|#00bfff|#000000|1|1|1|1|20241212152213_99018bb44d8b182ff31b89259420f8e3.png|1|1|0|12|hatch|2').checked = true;
            var parameter = 'layer**' + this.selectedkewenangan + '**' + kodedi + '**' + this.selectedbalai + '**' + this.tanggal + '**' + this.sdtanggal + '**';
            const message = this.linknya + '|||' + parameter + '|||true|' + 'layer|20|parastapainnovationbendung-GetdataBendung?adi=parastapa|2|#00bfff|#000000|1|1|1|1|20241212152213_99018bb44d8b182ff31b89259420f8e3.png|1|1|0|12|hatch|2';
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/map.html';
            iframe.contentWindow.postMessage(message, "*");

        },
        kediinfrastrukturterpilih(kodedi) {
            this.closeNavInfoBawah();
            this.closeNavInfo();
            this.closeNavLayer();
            document.getElementById('layer|21|parastapainnovationbendungnew-GetdataBendungNew?adi=parastapa|2|#00bfff|#000000|1|1|1|1|20241213133550_0131ccdf4c9828454f87c3d84c17ce1c.png|1|1|0|12|hatch|2').checked = true;
            var parameter = 'layer**' + this.selectedkewenangan + '**' + kodedi + '**' + this.selectedbalai + '**' + this.tanggal + '**' + this.sdtanggal + '**';
            const message = this.linknya + '|||' + parameter + '|||true|' + 'layer|21|parastapainnovationbendungnew-GetdataBendungNew?adi=parastapa|2|#00bfff|#000000|1|1|1|1|20241213133550_0131ccdf4c9828454f87c3d84c17ce1c.png|1|1|0|12|hatch|2';
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/map.html';
            iframe.contentWindow.postMessage(message, "*");

        },
        openNavLayer() {
            this.tutupmodalTanggal();
            if (document.getElementById("mySidenavLayer").style.width == "350px") {
                document.getElementById("mySidenavLayer").style.width = "0";
            } else {
                document.getElementById("mySidenavLayer").style.width = "350px";
            }
        },
        closeNavLayer() {
            document.getElementById("mySidenavLayer").style.width = "0";
        },
        closeNavInfo() {
            document.getElementById("myInfoLayer").style.width = "0";
        },
        async openNavInfoBawah() {
            this.tampilj2a098002 = '';
            try {
                var acuan = document.getElementById('acuanj2a098002').innerHTML;
                var kode = document.getElementById('kodej2a098002').innerHTML;
                var isi = document.getElementById('isinyaj2a098002').innerHTML;
                this.tampilj2a098002 = document.getElementById('tampilj2a098002').innerHTML;
                //alert(acuan);
                //alert(kode);
                //alert(isi);
                //alert(this.tampilj2a098002);
                if (this.tampilj2a098002 == 'PROFIL') {
                    this.halamanloading = true;
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                        },
                    });
                    var random = Math.random();
                    await mainAPI.get("parastapainnovation_profil_di_imc-GetProfilDI?random=" + random + "&acuan=" + acuan + "&kode=" + kode + "&isi=" + isi).then(
                        Response => {
                            this.datapesan = Response.data.message;
                            if (this.datapesan == 'data diketemukan') {
                                this.dataisi = [];
                                this.dataisi = Response.data.resume[0].Content;
                                this.datadetail = [];
                                this.datadetail = Response.data.content.data;
                                //alert(this.datadetail);
                                if (document.getElementById("myInfoBawah").style.width == "90vw") {
                                    document.getElementById("myInfoBawah").style.height = "0";
                                } else {
                                    document.getElementById("myInfoBawah").style.width = "100vw";
                                    document.getElementById("myInfoBawah").style.height = "100vh";
                                }
                            }

                            this.halamanloading = false;
                        }
                    ).catch(error => {
                        console.log(error);
                        //this.ClearlocalStorage();
                        this.halamanloading = false;
                    });
                }
                if (this.tampilj2a098002 == 'KEANDALAN') {
                    this.halamanloading = true;
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                        },
                    });
                    var random = Math.random();
                    await mainAPI.get("parastapainnovation_profil_di_imc-GetKendandalanAirDI?random=" + random + "&acuan=" + acuan + "&kode=" + kode + "&isi=" + isi).then(
                        Response => {
                            this.datapesan = Response.data.message;
                            if (this.datapesan == 'data diketemukan') {
                                this.dataisi = [];
                                this.dataisi = Response.data.resume[0].Content;
                                this.datadetail = [];
                                this.datadetail = Response.data.content.data;
                                //alert(this.datadetail);
                                if (document.getElementById("myInfoBawah").style.width == "90vw") {
                                    document.getElementById("myInfoBawah").style.height = "0";
                                } else {
                                    document.getElementById("myInfoBawah").style.width = "100vw";
                                    document.getElementById("myInfoBawah").style.height = "100vh";
                                }
                            }

                            this.halamanloading = false;
                        }
                    ).catch(error => {
                        console.log(error);
                        //this.ClearlocalStorage();
                        this.halamanloading = false;
                    });
                }

                if (this.tampilj2a098002 == 'INFRASTRUKTUR') {
                    this.halamanloading = true;
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                        },
                    });
                    var random = Math.random();
                    await mainAPI.get("parastapainnovation_profil_di_imc-GetKondisiInfrastruktur?random=" + random + "&acuan=" + acuan + "&kode=" + kode + "&isi=" + isi).then(
                        Response => {
                            this.datapesan = Response.data.message;
                            if (this.datapesan == 'data diketemukan') {
                                this.dataisi = [];
                                this.dataisi = Response.data.resume[0].Content;
                                this.datadetail = [];
                                this.datadetail = Response.data.content.data;
                                //alert(this.datadetail);
                                if (document.getElementById("myInfoBawah").style.width == "90vw") {
                                    document.getElementById("myInfoBawah").style.height = "0";
                                } else {
                                    document.getElementById("myInfoBawah").style.width = "100vw";
                                    document.getElementById("myInfoBawah").style.height = "100vh";
                                }
                            }

                            this.halamanloading = false;
                        }
                    ).catch(error => {
                        console.log(error);
                        //this.ClearlocalStorage();
                        this.halamanloading = false;
                    });
                }
                if (this.tampilj2a098002 == 'KELEMBAGAAN') {
                    this.halamanloading = true;
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                        },
                    });
                    var random = Math.random();
                    await mainAPI.get("parastapainnovation_profil_di_imc-GetKelembagaan?random=" + random + "&acuan=" + acuan + "&kode=" + kode + "&isi=" + isi).then(
                        Response => {
                            this.datapesan = Response.data.message;
                            this.IP3A = 0;
                            this.IP3ABE = 0;
                            this.IP3ABB = 0;
                            this.IP3ASB = 0;
                            this.IP3AB = 0;
                            this.IP3AM = 0;

                            this.GP3A = 0;
                            this.GP3ABE = 0;
                            this.GP3ABB = 0;
                            this.GP3ASB = 0;
                            this.GP3AB = 0;
                            this.GP3AM = 0;

                            this.P3A = 0;
                            this.P3ABE = 0;
                            this.P3ABB = 0;
                            this.P3ASB = 0;
                            this.P3AB = 0;
                            this.P3AM = 0;

                            if (this.datapesan == 'data diketemukan') {
                                this.dataisi = [];
                                this.dataisi = Response.data.resume[0].Content;
                                this.dataresume = [];
                                this.dataresume = Response.data.content.data;
                                this.IP3A = this.dataresume.reduce((a, { TotalIP3A: e }) => a + e, 0);
                                this.IP3ABE = this.dataresume.reduce((a, { IP3ABE: e }) => a + e, 0);
                                this.IP3ABB = this.dataresume.reduce((a, { IP3ABB: e }) => a + e, 0);
                                this.IP3ASB = this.dataresume.reduce((a, { IP3ASB: e }) => a + e, 0);
                                this.IP3AB = this.dataresume.reduce((a, { IP3AB: e }) => a + e, 0);
                                this.IP3AM = this.dataresume.reduce((a, { IP3AM: e }) => a + e, 0);

                                this.GP3A = this.dataresume.reduce((a, { TotalGP3A: e }) => a + e, 0);
                                this.GP3ABE = this.dataresume.reduce((a, { GP3ABE: e }) => a + e, 0);
                                this.GP3ABB = this.dataresume.reduce((a, { GP3ABB: e }) => a + e, 0);
                                this.GP3ASB = this.dataresume.reduce((a, { GP3ASB: e }) => a + e, 0);
                                this.GP3AB = this.dataresume.reduce((a, { GP3AB: e }) => a + e, 0);
                                this.GP3AM = this.dataresume.reduce((a, { GP3AM: e }) => a + e, 0);

                                this.P3A = this.dataresume.reduce((a, { TotalP3A: e }) => a + e, 0);
                                this.P3ABE = this.dataresume.reduce((a, { P3ABE: e }) => a + e, 0);
                                this.P3ABB = this.dataresume.reduce((a, { P3ABB: e }) => a + e, 0);
                                this.P3ASB = this.dataresume.reduce((a, { P3ASB: e }) => a + e, 0);
                                this.P3AB = this.dataresume.reduce((a, { P3AB: e }) => a + e, 0);
                                this.P3AM = this.dataresume.reduce((a, { P3AM: e }) => a + e, 0);

                                this.datadetail = [];
                                this.datadetail = Response.data.content.data;

                                //alert(this.datadetail);
                                if (document.getElementById("myInfoBawah").style.width == "90vw") {
                                    document.getElementById("myInfoBawah").style.height = "0";
                                } else {
                                    document.getElementById("myInfoBawah").style.width = "100vw";
                                    document.getElementById("myInfoBawah").style.height = "100vh";
                                }
                            }

                            this.halamanloading = false;
                        }
                    ).catch(error => {
                        console.log(error);
                        //this.ClearlocalStorage();
                        this.halamanloading = false;
                    });
                }
                if (this.tampilj2a098002 == 'INFRASTRUKTURBENDUNG') {
                    this.halamanloading = true;
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                        },
                    });
                    var random = Math.random();
                    await mainAPI.get("parastapainnovation_profil_detail-GetDetailInfrastruktur?random=" + random + "&acuan=" + acuan + "&kode=" + kode + "&isi=" + isi).then(
                        Response => {
                            this.datapesan = Response.data.message;
                            if (this.datapesan == 'data diketemukan') {
                                this.dataisi = [];
                                this.dataisi = Response.data.resume[0].Content;
                                this.datadetail = [];
                                this.datadetail = Response.data.content.data;
                                //alert(this.datadetail);
                                if (document.getElementById("myInfoBawah").style.width == "90vw") {
                                    document.getElementById("myInfoBawah").style.height = "0";
                                } else {
                                    document.getElementById("myInfoBawah").style.width = "100vw";
                                    document.getElementById("myInfoBawah").style.height = "100vh";
                                }
                                this.halamanloading = false;
                            }
                            else {
                                this.dataisi = [];
                                this.datadetail = [];
                                swal.mixin({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000
                                }).fire({
                                    icon: 'warning',
                                    title: 'Data Tidak Diketemukan..',
                                });
                                $('.swal2-container').css("z-index", '999999');
                                this.halamanloading = false;
                                return false;
                            }


                        }
                    ).catch(error => {
                        console.log(error);
                        //this.ClearlocalStorage();
                        this.halamanloading = false;
                    });
                }
                if (this.tampilj2a098002 == 'KELEMBAGAANBENDUNG') {
                    this.halamanloading = true;
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                        },
                    });
                    var random = Math.random();
                    await mainAPI.get("parastapainnovation_profil_detail-GetDetailKelembagaan?random=" + random + "&acuan=" + acuan + "&kode=" + kode + "&isi=" + isi).then(
                        Response => {
                            this.datapesan = Response.data.message;
                            this.IP3A = 0;
                            this.IP3ABE = 0;
                            this.IP3ABB = 0;
                            this.IP3ASB = 0;
                            this.IP3AB = 0;
                            this.IP3AM = 0;

                            this.GP3A = 0;
                            this.GP3ABE = 0;
                            this.GP3ABB = 0;
                            this.GP3ASB = 0;
                            this.GP3AB = 0;
                            this.GP3AM = 0;

                            this.P3A = 0;
                            this.P3ABE = 0;
                            this.P3ABB = 0;
                            this.P3ASB = 0;
                            this.P3AB = 0;
                            this.P3AM = 0;

                            if (this.datapesan == 'data diketemukan') {
                                this.dataisi = [];
                                this.dataisi = Response.data.resume[0].Content;
                                this.datadetail = [];
                                this.datadetail = Response.data.content.data;
                                this.IP3A = this.datadetail.reduce((a, { TotalIP3A: e }) => a + e, 0);
                                this.IP3ABE = this.datadetail.reduce((a, { IP3ABE: e }) => a + e, 0);
                                this.IP3ABB = this.datadetail.reduce((a, { IP3ABB: e }) => a + e, 0);
                                this.IP3ASB = this.datadetail.reduce((a, { IP3ASB: e }) => a + e, 0);
                                this.IP3AB = this.datadetail.reduce((a, { IP3AB: e }) => a + e, 0);
                                this.IP3AM = this.datadetail.reduce((a, { IP3AM: e }) => a + e, 0);

                                this.GP3A = this.datadetail.reduce((a, { TotalGP3A: e }) => a + e, 0);
                                this.GP3ABE = this.datadetail.reduce((a, { GP3ABE: e }) => a + e, 0);
                                this.GP3ABB = this.datadetail.reduce((a, { GP3ABB: e }) => a + e, 0);
                                this.GP3ASB = this.datadetail.reduce((a, { GP3ASB: e }) => a + e, 0);
                                this.GP3AB = this.datadetail.reduce((a, { GP3AB: e }) => a + e, 0);
                                this.GP3AM = this.datadetail.reduce((a, { GP3AM: e }) => a + e, 0);

                                this.P3A = this.datadetail.reduce((a, { TotalP3A: e }) => a + e, 0);
                                this.P3ABE = this.datadetail.reduce((a, { P3ABE: e }) => a + e, 0);
                                this.P3ABB = this.datadetail.reduce((a, { P3ABB: e }) => a + e, 0);
                                this.P3ASB = this.datadetail.reduce((a, { P3ASB: e }) => a + e, 0);
                                this.P3AB = this.datadetail.reduce((a, { P3AB: e }) => a + e, 0);
                                this.P3AM = this.datadetail.reduce((a, { P3AM: e }) => a + e, 0);

                                this.datalist = [];
                                this.datalist = Response.data.content.detail;
                                //alert(this.datalist);
                                if (document.getElementById("myInfoBawah").style.width == "90vw") {
                                    document.getElementById("myInfoBawah").style.height = "0";
                                } else {
                                    document.getElementById("myInfoBawah").style.width = "100vw";
                                    document.getElementById("myInfoBawah").style.height = "100vh";
                                }
                                this.halamanloading = false;
                            }
                            else {
                                this.dataisi = [];
                                this.datadetail = [];
                                swal.mixin({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000
                                }).fire({
                                    icon: 'warning',
                                    title: 'Data Tidak Diketemukan..',
                                });
                                $('.swal2-container').css("z-index", '999999');
                                this.halamanloading = false;
                                return false;
                            }

                        }
                    ).catch(error => {
                        console.log(error);
                        //this.ClearlocalStorage();
                        this.halamanloading = false;
                    });
                }
                if (this.tampilj2a098002 == 'PENGELOLAANBENDUNG') {
                    this.halamanloading = true;
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                        },
                    });
                    var random = Math.random();
                    await mainAPI.get("parastapainnovation_profil_detail-GetDetailPengelolaan?random=" + random + "&acuan=" + acuan + "&kode=" + kode + "&isi=" + isi).then(
                        Response => {
                            this.datapesan = Response.data.message;
                            if (this.datapesan == 'data diketemukan') {
                                this.series = [];
                                this.dataisi = [];

                                this.tinggi = [];
                                this.koreksi = [];
                                this.ukmhr = [];
                                this.fu = [];

                                this.suhu = [];
                                this.ea = [];
                                this.w = [];
                                this.satuminw = [];
                                this.ft = [];
                                this.acuanra = [];
                                this.ra1 = [];
                                this.ra2 = [];
                                this.ra = [];

                                this.dataisi = Response.data.resume[0].Content;
                                this.datadetail = [];
                                this.datadetail = Response.data.content.data;
                                this.datadetailtab2 = [];
                                this.datadetailtab2 = Response.data.detail.data;
                                this.dataklimatologi = [];
                                this.dataklimatologi = Response.data.klimatologi.data;
                                var tinggi = Response.data.klimatologi.data[0].tinggipos;
                                var nilaipositif = Response.data.klimatologi.data[0].nilaipositif;
                                this.acuanra = Response.data.masterra.data;
                                //alert(JSON.stringify(this.acuanra));
                                this.tinggi = Response.data.tinggi;
                                this.koreksi = Response.data.koreksi;
                                this.ukmhr = Response.data.ukmhr;
                                this.fu = Response.data.fu;

                                this.suhu = Response.data.suhu;
                                this.ea = Response.data.ea;
                                this.w = Response.data.w;
                                this.satuminw = Response.data.satuminw;
                                this.ft = Response.data.ft;

                                //alert(this.fu);
                                this.dataet0 = [];
                                for (let i = 0; i < this.dataklimatologi.length; i++) {
                                    if (tinggi <= 6) {
                                        var pengkalipertama = this.forecast(tinggi, this.tinggi, this.koreksi);
                                    }
                                    else {
                                        var pengkalipertama = this.koreksi[this.koreksi.length - 1];
                                    }


                                    if (i == 0) {
                                        var foundra = [];
                                        foundra = this.acuanra.filter((element) => (element.LS == nilaipositif));
                                        if (foundra.length == 0) {
                                            foundra = this.acuanra.filter((element) => (element.LS == (nilaipositif - 1)) || (element.LS == (nilaipositif + 1)));
                                            for (let z = 0; z < foundra.length; z++) {
                                                this.ra1.push(foundra[z].Januari);
                                                this.ra2.push(foundra[z].LS);
                                            }
                                            var nilaira = this.forecast(nilaipositif, this.ra1, this.ra2);
                                        }
                                        else {
                                            var nilaira = foundra[0].Januari;
                                        }
                                        var suhuc = 1.1;

                                    }
                                    if (i == 1) {
                                        var foundra = [];
                                        foundra = this.acuanra.filter((element) => (element.LS == nilaipositif));
                                        if (foundra.length == 0) {
                                            foundra = this.acuanra.filter((element) => (element.LS == (nilaipositif - 1)) || (element.LS == (nilaipositif + 1)));
                                            for (let z = 0; z < foundra.length; z++) {
                                                this.ra1.push(foundra[z].Februari);
                                                this.ra2.push(foundra[z].LS);
                                            }
                                            var nilaira = this.forecast(nilaipositif, this.ra1, this.ra2);
                                        }
                                        else {
                                            var nilaira = foundra[0].Februari;
                                        }
                                        var suhuc = 1.1;
                                    }
                                    if (i == 2) {
                                        var foundra = [];
                                        foundra = this.acuanra.filter((element) => (element.LS == nilaipositif));
                                        if (foundra.length == 0) {
                                            foundra = this.acuanra.filter((element) => (element.LS == (nilaipositif - 1)) || (element.LS == (nilaipositif + 1)));
                                            for (let z = 0; z < foundra.length; z++) {
                                                this.ra1.push(foundra[z].Maret);
                                                this.ra2.push(foundra[z].LS);
                                            }
                                            var nilaira = this.forecast(nilaipositif, this.ra1, this.ra2);
                                        }
                                        else {
                                            var nilaira = foundra[0].Maret;
                                        }
                                        var suhuc = 1.1;
                                    }
                                    if (i == 3) {
                                        var foundra = [];
                                        foundra = this.acuanra.filter((element) => (element.LS == nilaipositif));
                                        if (foundra.length == 0) {
                                            foundra = this.acuanra.filter((element) => (element.LS == (nilaipositif - 1)) || (element.LS == (nilaipositif + 1)));
                                            for (let z = 0; z < foundra.length; z++) {
                                                this.ra1.push(foundra[z].April);
                                                this.ra2.push(foundra[z].LS);
                                            }
                                            var nilaira = this.forecast(nilaipositif, this.ra1, this.ra2);
                                        }
                                        else {
                                            var nilaira = foundra[0].April;
                                        }
                                        var suhuc = 1.1;
                                    }
                                    if (i == 4) {
                                        var foundra = [];
                                        foundra = this.acuanra.filter((element) => (element.LS == nilaipositif));
                                        if (foundra.length == 0) {
                                            foundra = this.acuanra.filter((element) => (element.LS == (nilaipositif - 1)) || (element.LS == (nilaipositif + 1)));
                                            for (let z = 0; z < foundra.length; z++) {
                                                this.ra1.push(foundra[z].Mei);
                                                this.ra2.push(foundra[z].LS);
                                            }
                                            var nilaira = this.forecast(nilaipositif, this.ra1, this.ra2);
                                        }
                                        else {
                                            var nilaira = foundra[0].Mei;
                                        }
                                        var suhuc = 1.1;
                                    }
                                    if (i == 5) {
                                        var foundra = [];
                                        foundra = this.acuanra.filter((element) => (element.LS == nilaipositif));
                                        if (foundra.length == 0) {
                                            foundra = this.acuanra.filter((element) => (element.LS == (nilaipositif - 1)) || (element.LS == (nilaipositif + 1)));
                                            for (let z = 0; z < foundra.length; z++) {
                                                this.ra1.push(foundra[z].Juni);
                                                this.ra2.push(foundra[z].LS);
                                            }
                                            var nilaira = this.forecast(nilaipositif, this.ra1, this.ra2);
                                        }
                                        else {
                                            var nilaira = foundra[0].Juni;
                                        }
                                        var suhuc = 1.1;
                                    }
                                    if (i == 6) {
                                        var foundra = [];
                                        foundra = this.acuanra.filter((element) => (element.LS == nilaipositif));
                                        if (foundra.length == 0) {
                                            foundra = this.acuanra.filter((element) => (element.LS == (nilaipositif - 1)) || (element.LS == (nilaipositif + 1)));
                                            for (let z = 0; z < foundra.length; z++) {
                                                this.ra1.push(foundra[z].Juli);
                                                this.ra2.push(foundra[z].LS);
                                            }
                                            var nilaira = this.forecast(nilaipositif, this.ra1, this.ra2);
                                        }
                                        else {
                                            var nilaira = foundra[0].Juli;
                                        }
                                        var suhuc = 1.1;
                                    }
                                    if (i == 7) {
                                        var foundra = [];
                                        foundra = this.acuanra.filter((element) => (element.LS == nilaipositif));
                                        if (foundra.length == 0) {
                                            foundra = this.acuanra.filter((element) => (element.LS == (nilaipositif - 1)) || (element.LS == (nilaipositif + 1)));
                                            for (let z = 0; z < foundra.length; z++) {
                                                this.ra1.push(foundra[z].Agustus);
                                                this.ra2.push(foundra[z].LS);
                                            }
                                            var nilaira = this.forecast(nilaipositif, this.ra1, this.ra2);
                                        }
                                        else {
                                            var nilaira = foundra[0].Agustus;
                                        }
                                        var suhuc = 1.1;
                                    }
                                    if (i == 8) {
                                        var foundra = [];
                                        foundra = this.acuanra.filter((element) => (element.LS == nilaipositif));
                                        if (foundra.length == 0) {
                                            foundra = this.acuanra.filter((element) => (element.LS == (nilaipositif - 1)) || (element.LS == (nilaipositif + 1)));
                                            for (let z = 0; z < foundra.length; z++) {
                                                this.ra1.push(foundra[z].September);
                                                this.ra2.push(foundra[z].LS);
                                            }
                                            var nilaira = this.forecast(nilaipositif, this.ra1, this.ra2);
                                        }
                                        else {
                                            var nilaira = foundra[0].September;
                                        }
                                        var suhuc = 1.1;
                                    }
                                    if (i == 9) {
                                        var foundra = [];
                                        foundra = this.acuanra.filter((element) => (element.LS == nilaipositif));
                                        if (foundra.length == 0) {
                                            foundra = this.acuanra.filter((element) => (element.LS == (nilaipositif - 1)) || (element.LS == (nilaipositif + 1)));
                                            for (let z = 0; z < foundra.length; z++) {
                                                this.ra1.push(foundra[z].Oktober);
                                                this.ra2.push(foundra[z].LS);
                                            }
                                            var nilaira = this.forecast(nilaipositif, this.ra1, this.ra2);
                                        }
                                        else {
                                            var nilaira = foundra[0].Oktober;
                                        }
                                        var suhuc = 1.1;
                                    }
                                    if (i == 10) {
                                        var foundra = [];
                                        foundra = this.acuanra.filter((element) => (element.LS == nilaipositif));
                                        if (foundra.length == 0) {
                                            foundra = this.acuanra.filter((element) => (element.LS == (nilaipositif - 1)) || (element.LS == (nilaipositif + 1)));
                                            for (let z = 0; z < foundra.length; z++) {
                                                this.ra1.push(foundra[z].November);
                                                this.ra2.push(foundra[z].LS);
                                            }
                                            var nilaira = this.forecast(nilaipositif, this.ra1, this.ra2);
                                        }
                                        else {
                                            var nilaira = foundra[0].November;
                                        }
                                        var suhuc = 1.15;
                                    }
                                    if (i == 11) {
                                        var foundra = [];
                                        foundra = this.acuanra.filter((element) => (element.LS == nilaipositif));
                                        if (foundra.length == 0) {
                                            foundra = this.acuanra.filter((element) => (element.LS == (nilaipositif - 1)) || (element.LS == (nilaipositif + 1)));
                                            for (let z = 0; z < foundra.length; z++) {
                                                this.ra1.push(foundra[z].Desember);
                                                this.ra2.push(foundra[z].LS);
                                            }
                                            var nilaira = this.forecast(nilaipositif, this.ra1, this.ra2);
                                        }
                                        else {
                                            var nilaira = foundra[0].Desember;
                                        }
                                        var suhuc = 1.15;
                                    }

                                    var fu = this.forecast((this.dataklimatologi[i].kecepatanangin * pengkalipertama), this.fu, this.ukmhr);
                                    var w = this.forecast(this.dataklimatologi[i].temprata2, this.w, this.suhu);

                                    var Rs = (0.25+0.54*this.dataklimatologi[i].sinarmatahari/100)*nilaira;
                                    var Rns = (1-0.25)*Rs;

                                    var carisuhusebelum = [];
                                    carisuhusebelum = this.suhu.filter((element) => (element < this.dataklimatologi[i].temprata2));
                                    var nilaicarisuhusebelum = carisuhusebelum[carisuhusebelum.length - 1];
                                    //alert(nilaicarisuhusebelum);
                                    var indexnilaicarisuhusebelum = this.suhu.indexOf(nilaicarisuhusebelum);
                                    //alert(indexnilaicarisuhusebelum);

                                    var carisuhusesudah = [];
                                    carisuhusesudah = this.suhu.filter((element) => (element > this.dataklimatologi[i].temprata2));
                                    var nilaicarisuhusesudah = carisuhusesudah[carisuhusesudah.length - 1];
                                    //alert(nilaicarisuhusesudah);
                                    var indexnilaicarisuhusesudah = this.suhu.indexOf(nilaicarisuhusesudah);

                                    var arraysuhu = [];
                                    arraysuhu.push(this.suhu[indexnilaicarisuhusebelum],this.suhu[indexnilaicarisuhusesudah]);
                                    //alert(arraysuhu);
                                    var arrayft = [];
                                    arrayft.push(this.ft[indexnilaicarisuhusebelum],this.ft[indexnilaicarisuhusesudah]);

                                    var efTe = this.forecast((this.dataklimatologi[i].temprata2), arrayft, arraysuhu);

                                    var hitunganefeDe = ((0.0006 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.0051 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.525 * this.dataklimatologi[i].temprata2) + 5.9672) * (this.dataklimatologi[i].rh / 100);
                                    var hasilhitunganefeDe = 0.34 - 0.044 * Math.sqrt(hitunganefeDe);
                                    var efnN = 0.1 + 0.9 * this.dataklimatologi[i].sinarmatahari/100;
                                    var Rn1 = efTe*hasilhitunganefeDe*efnN;
                                    var erEn = Rns-Rn1;

                                    var hitunganw = this.forecast((this.dataklimatologi[i].temprata2), this.w, this.suhu);
                                    var weren = erEn*hitunganw;

                                    var etnol = suhuc*(weren+((1 - w) * fu * (((0.0006 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.0051 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.525 * this.dataklimatologi[i].temprata2) + 5.9672) - (((0.0006 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.0051 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.525 * this.dataklimatologi[i].temprata2) + 5.9672) * (this.dataklimatologi[i].rh / 100)))));
                                    if (i == 0) {
                                        var etnolrata2 = etnol*31;
                                        var etnolminggu1 = etnol*15;
                                        var etnolminggu2 = etnolrata2-etnolminggu1;
                                    }
                                    if (i == 1) {
                                        var etnolrata2 = etnol*28;
                                        var etnolminggu1 = etnol*14;
                                        var etnolminggu2 = etnolrata2-etnolminggu1;
                                    }
                                    if (i == 2) {
                                        var etnolrata2 = etnol*31;
                                        var etnolminggu1 = etnol*15;
                                        var etnolminggu2 = etnolrata2-etnolminggu1;
                                    }
                                    if (i == 3) {
                                        var etnolrata2 = etnol*30;
                                        var etnolminggu1 = etnol*15;
                                        var etnolminggu2 = etnolrata2-etnolminggu1;
                                    }
                                    if (i == 4) {
                                        var etnolrata2 = etnol*31;
                                        var etnolminggu1 = etnol*15;
                                        var etnolminggu2 = etnolrata2-etnolminggu1;
                                    }
                                    if (i == 5) {
                                        var etnolrata2 = etnol*30;
                                        var etnolminggu1 = etnol*15;
                                        var etnolminggu2 = etnolrata2-etnolminggu1;
                                    }
                                    if (i == 6) {
                                        var etnolrata2 = etnol*31;
                                        var etnolminggu1 = etnol*15;
                                        var etnolminggu2 = etnolrata2-etnolminggu1;
                                    }
                                    if (i == 7) {
                                        var etnolrata2 = etnol*31;
                                        var etnolminggu1 = etnol*15;
                                        var etnolminggu2 = etnolrata2-etnolminggu1;
                                    }
                                    if (i == 8) {
                                        var etnolrata2 = etnol*30;
                                        var etnolminggu1 = etnol*15;
                                        var etnolminggu2 = etnolrata2-etnolminggu1;
                                    }
                                    if (i == 9) {
                                        var etnolrata2 = etnol*31;
                                        var etnolminggu1 = etnol*15;
                                        var etnolminggu2 = etnolrata2-etnolminggu1;
                                    }
                                    if (i == 10) {
                                        var etnolrata2 = etnol*30;
                                        var etnolminggu1 = etnol*15;
                                        var etnolminggu2 = etnolrata2-etnolminggu1;
                                    }
                                    if (i == 11) {
                                        var etnolrata2 = etnol*31;
                                        var etnolminggu1 = etnol*15;
                                        var etnolminggu2 = etnolrata2-etnolminggu1;
                                    }
                                    this.dataet0.push({
                                        "temperatur": this.dataklimatologi[i].temprata2,
                                        "rh": this.dataklimatologi[i].rh,
                                        "kecepatanangin": this.dataklimatologi[i].kecepatanangin,
                                        "kecepatananginmdt": this.dataklimatologi[i].kecepatanangin * 1000 / (24 * 3600),
                                        "sinarmatahari": this.dataklimatologi[i].sinarmatahari,
                                        "ea": (0.0006 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.0051 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.525 * this.dataklimatologi[i].temprata2) + 5.9672,
                                        "rhmean": this.dataklimatologi[i].rh / 100,
                                        "tekananuapjenuh": ((0.0006 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.0051 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.525 * this.dataklimatologi[i].temprata2) + 5.9672) * (this.dataklimatologi[i].rh / 100),
                                        "eaed": ((0.0006 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.0051 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.525 * this.dataklimatologi[i].temprata2) + 5.9672) - (((0.0006 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.0051 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.525 * this.dataklimatologi[i].temprata2) + 5.9672) * (this.dataklimatologi[i].rh / 100)),
                                        "fu": fu,
                                        "satuminw": 1 - w,
                                        "kaliw": (1 - w) * fu * (((0.0006 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.0051 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.525 * this.dataklimatologi[i].temprata2) + 5.9672) - (((0.0006 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.0051 * (this.dataklimatologi[i].temprata2 * this.dataklimatologi[i].temprata2)) + (0.525 * this.dataklimatologi[i].temprata2) + 5.9672) * (this.dataklimatologi[i].rh / 100))),
                                        "nilaira": nilaira,
                                        "Rs": Rs,
                                        "Rns": Rns,
                                        "efTe": efTe,
                                        "efeDe": hasilhitunganefeDe,
                                        "efnN": efnN,
                                        "Rn1": Rn1,
                                        "erEn": erEn,
                                        "w": hitunganw,
                                        "weren": weren,
                                        "suhuc": suhuc,
                                        "etnol": etnol,
                                        "etnolrata2": etnolrata2,
                                        "etnolminggu1": etnolminggu1,
                                        "etnolminggu2": etnolminggu2,
                                    });
                                }
                                this.rata2HujanHarian = [];
                                this.Jumlahdatarata2HujanHarian = 0;
                                this.shortJanuari1 = [];
                                this.shortJanuari2 = [];
                                this.shortFebruari1 = [];
                                this.shortFebruari2 = [];
                                this.shortMaret1 = [];
                                this.shortMaret2 = [];
                                this.shortApril1 = [];
                                this.shortApril2 = [];
                                this.shortMei1 = [];
                                this.shortMei2 = [];
                                this.shortJuni1 = [];
                                this.shortJuni2 = [];
                                this.shortJuli1 = [];
                                this.shortJuli2 = [];
                                this.shortAgustus1 = [];
                                this.shortAgustus2 = [];
                                this.shortSeptember1 = [];
                                this.shortSeptember2 = [];
                                this.shortOktober1 = [];
                                this.shortOktober2 = [];
                                this.shortNovember1 = [];
                                this.shortNovember2 = [];
                                this.shortDesember1 = [];
                                this.shortDesember2 = [];
                                this.rata2HujanHarianPerHari = [];
                                this.rata2HujanHarianPerHariGrafik = [];

                                this.totalJanuari1 = 0;
                                this.totalJanuari2 = 0;
                                this.totalJanuari1 = this.datadetail.reduce((a, { Januari1: e }) => a + e, 0);
                                this.totalJanuari2 = this.datadetail.reduce((a, { Januari2: e }) => a + e, 0);
                                this.totalJanuari = this.totalJanuari1 + this.totalJanuari2;
                                this.rataJanuari1 = this.totalJanuari1 / Response.data.content.data.length;
                                this.rataJanuari2 = this.totalJanuari2 / Response.data.content.data.length;
                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Januari1);
                                    this.shortJanuari1.push(this.datadetail[i].Januari1);
                                    this.shortJanuari1.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    });
                                }
                                this.maxJanuari1 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Januari2);
                                    this.shortJanuari2.push(this.datadetail[i].Januari2);
                                    this.shortJanuari2.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    });
                                }
                                this.maxJanuari2 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Januari1);
                                }
                                this.minJanuari1 = Math.min(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Januari2);
                                }
                                this.minJanuari2 = Math.min(...numbers);

                                this.totalFebruari1 = 0;
                                this.totalFebruari2 = 0;
                                this.totalFebruari1 = this.datadetail.reduce((a, { Februari1: e }) => a + e, 0);
                                this.totalFebruari2 = this.datadetail.reduce((a, { Februari2: e }) => a + e, 0);
                                this.totalFebruari = this.totalFebruari1 + this.totalFebruari2;
                                this.rataFebruari1 = this.totalFebruari1 / Response.data.content.data.length;
                                this.rataFebruari2 = this.totalFebruari2 / Response.data.content.data.length;
                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Februari1);
                                    this.shortFebruari1.push(this.datadetail[i].Februari1);
                                    this.shortFebruari1.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    });
                                }
                                this.maxFebruari1 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Februari2);
                                    this.shortFebruari2.push(this.datadetail[i].Februari2);
                                    this.shortFebruari2.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    });
                                }
                                this.maxFebruari2 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Februari1);
                                }
                                this.minFebruari1 = Math.min(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Februari2);
                                }
                                this.minFebruari2 = Math.min(...numbers);

                                this.totalMaret1 = 0;
                                this.totalMaret2 = 0;
                                this.totalMaret1 = this.datadetail.reduce((a, { Maret1: e }) => a + e, 0);
                                this.totalMaret2 = this.datadetail.reduce((a, { Maret2: e }) => a + e, 0);
                                this.totalMaret = this.totalMaret1 + this.totalMaret2;
                                this.rataMaret1 = this.totalMaret1 / Response.data.content.data.length;
                                this.rataMaret2 = this.totalMaret2 / Response.data.content.data.length;
                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Maret1);
                                    this.shortMaret1.push(this.datadetail[i].Maret1);
                                    this.shortMaret1.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    });
                                }
                                this.maxMaret1 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Maret2);
                                    this.shortMaret2.push(this.datadetail[i].Maret2);
                                    this.shortMaret2.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    });
                                }
                                this.maxMaret2 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Maret1);
                                }
                                this.minMaret1 = Math.min(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Maret2);
                                }
                                this.minMaret2 = Math.min(...numbers);

                                this.totalApril1 = 0;
                                this.totalApril2 = 0;
                                this.totalApril1 = this.datadetail.reduce((a, { April1: e }) => a + e, 0);
                                this.totalApril2 = this.datadetail.reduce((a, { April2: e }) => a + e, 0);
                                this.totalApril = this.totalApril1 + this.totalApril2;
                                this.rataApril1 = this.totalApril1 / Response.data.content.data.length;
                                this.rataApril2 = this.totalApril2 / Response.data.content.data.length;
                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].April1);
                                    this.shortApril1.push(this.datadetail[i].April1);
                                    this.shortApril1.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    });
                                }
                                this.maxApril1 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].April2);
                                    this.shortApril2.push(this.datadetail[i].April2);
                                    this.shortApril2.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    });
                                }
                                this.maxApril2 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].April1);
                                }
                                this.minApril1 = Math.min(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].April2);
                                }
                                this.minApril2 = Math.min(...numbers);

                                this.totalMei1 = 0;
                                this.totalMei2 = 0;
                                this.totalMei1 = this.datadetail.reduce((a, { Mei1: e }) => a + e, 0);
                                this.totalMei2 = this.datadetail.reduce((a, { Mei2: e }) => a + e, 0);
                                this.totalMei = this.totalMei1 + this.totalMei2;
                                this.rataMei1 = this.totalMei1 / Response.data.content.data.length;
                                this.rataMei2 = this.totalMei2 / Response.data.content.data.length;
                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Mei1);
                                    this.shortMei1.push(this.datadetail[i].Mei1);
                                    this.shortMei1.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    });
                                }
                                this.maxMei1 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Mei2);
                                    this.shortMei2.push(this.datadetail[i].Mei2);
                                    this.shortMei2.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    });
                                }
                                this.maxMei2 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Mei1);
                                }
                                this.minMei1 = Math.min(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Mei2);
                                }
                                this.minMei2 = Math.min(...numbers);

                                this.totalJuni1 = 0;
                                this.totalJuni2 = 0;
                                this.totalJuni1 = this.datadetail.reduce((a, { Juni1: e }) => a + e, 0);
                                this.totalJuni2 = this.datadetail.reduce((a, { Juni2: e }) => a + e, 0);
                                this.totalJuni = this.totalJuni1 + this.totalJuni2;
                                this.rataJuni1 = this.totalJuni1 / Response.data.content.data.length;
                                this.rataJuni2 = this.totalJuni2 / Response.data.content.data.length;
                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Juni1);
                                    this.shortJuni1.push(this.datadetail[i].Juni1);
                                    this.shortJuni1.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    });
                                }
                                this.maxJuni1 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Juni2);
                                    this.shortJuni2.push(this.datadetail[i].Juni2);
                                    this.shortJuni2.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    })
                                }
                                this.maxJuni2 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Juni1);
                                }
                                this.minJuni1 = Math.min(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Juni2);
                                }
                                this.minJuni2 = Math.min(...numbers);

                                this.totalJuli1 = 0;
                                this.totalJuli2 = 0;
                                this.totalJuli1 = this.datadetail.reduce((a, { Juli1: e }) => a + e, 0);
                                this.totalJuli2 = this.datadetail.reduce((a, { Juli2: e }) => a + e, 0);
                                this.totalJuli = this.totalJuli1 + this.totalJuli2;
                                this.rataJuli1 = this.totalJuli1 / Response.data.content.data.length;
                                this.rataJuli2 = this.totalJuli2 / Response.data.content.data.length;
                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Juli1);
                                    this.shortJuli1.push(this.datadetail[i].Juli1);
                                    this.shortJuli1.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    })
                                }
                                this.maxJuli1 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Juli2);
                                    this.shortJuli2.push(this.datadetail[i].Juli2);
                                    this.shortJuli2.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    })
                                }
                                this.maxJuli2 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Juli1);
                                }
                                this.minJuli1 = Math.min(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Juli2);
                                }
                                this.minJuli2 = Math.min(...numbers);

                                this.totalAgustus1 = 0;
                                this.totalAgustus2 = 0;
                                this.totalAgustus1 = this.datadetail.reduce((a, { Agustus1: e }) => a + e, 0);
                                this.totalAgustus2 = this.datadetail.reduce((a, { Agustus2: e }) => a + e, 0);
                                this.totalAgustus = this.totalAgustus1 + this.totalAgustus2;
                                this.rataAgustus1 = this.totalAgustus1 / Response.data.content.data.length;
                                this.rataAgustus2 = this.totalAgustus2 / Response.data.content.data.length;
                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Agustus1);
                                    this.shortAgustus1.push(this.datadetail[i].Agustus1);
                                    this.shortAgustus1.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    })
                                }
                                this.maxAgustus1 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Agustus2);
                                    this.shortAgustus2.push(this.datadetail[i].Agustus2);
                                    this.shortAgustus2.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    })
                                }
                                this.maxAgustus2 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Agustus1);
                                }
                                this.minAgustus1 = Math.min(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Agustus2);
                                }
                                this.minAgustus2 = Math.min(...numbers);

                                this.totalSeptember1 = 0;
                                this.totalSeptember2 = 0;
                                this.totalSeptember1 = this.datadetail.reduce((a, { September1: e }) => a + e, 0);
                                this.totalSeptember2 = this.datadetail.reduce((a, { September2: e }) => a + e, 0);
                                this.totalSeptember = this.totalSeptember1 + this.totalSeptember2;
                                this.rataSeptember1 = this.totalSeptember1 / Response.data.content.data.length;
                                this.rataSeptember2 = this.totalSeptember2 / Response.data.content.data.length;
                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].September1);
                                    this.shortSeptember1.push(this.datadetail[i].September1);
                                    this.shortSeptember1.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    })
                                }
                                this.maxSeptember1 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].September2);
                                    this.shortSeptember2.push(this.datadetail[i].September2);
                                    this.shortSeptember2.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    })
                                }
                                this.maxSeptember2 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].September1);
                                }
                                this.minSeptember1 = Math.min(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].September2);
                                }
                                this.minSeptember2 = Math.min(...numbers);

                                this.totalOktober1 = 0;
                                this.totalOktober2 = 0;
                                this.totalOktober1 = this.datadetail.reduce((a, { Oktober1: e }) => a + e, 0);
                                this.totalOktober2 = this.datadetail.reduce((a, { Oktober2: e }) => a + e, 0);
                                this.totalOktober = this.totalOktober1 + this.totalOktober2;
                                this.rataOktober1 = this.totalOktober1 / Response.data.content.data.length;
                                this.rataOktober2 = this.totalOktober2 / Response.data.content.data.length;
                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Oktober1);
                                    this.shortOktober1.push(this.datadetail[i].Oktober1);
                                    this.shortOktober1.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    })
                                }
                                this.maxOktober1 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Oktober2);
                                    this.shortOktober2.push(this.datadetail[i].Oktober2);
                                    this.shortOktober2.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    })
                                }
                                this.maxOktober2 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Oktober1);
                                }
                                this.minOktober1 = Math.min(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Oktober2);
                                }
                                this.minOktober2 = Math.min(...numbers);

                                this.totalNovember1 = 0;
                                this.totalNovember2 = 0;
                                this.totalNovember1 = this.datadetail.reduce((a, { November1: e }) => a + e, 0);
                                this.totalNovember2 = this.datadetail.reduce((a, { November2: e }) => a + e, 0);
                                this.totalNovember = this.totalNovember1 + this.totalNovember2;
                                this.rataNovember1 = this.totalNovember1 / Response.data.content.data.length;
                                this.rataNovember2 = this.totalNovember2 / Response.data.content.data.length;
                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].November1);
                                    this.shortNovember1.push(this.datadetail[i].November1);
                                    this.shortNovember1.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    })
                                }
                                this.maxNovember1 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].November2);
                                    this.shortNovember2.push(this.datadetail[i].November2);
                                    this.shortNovember2.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    })
                                }
                                this.maxNovember2 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].November1);
                                }
                                this.minNovember1 = Math.min(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].November2);
                                }
                                this.minNovember2 = Math.min(...numbers);

                                this.totalDesember1 = 0;
                                this.totalDesember2 = 0;
                                this.totalDesember1 = this.datadetail.reduce((a, { Desember1: e }) => a + e, 0);
                                this.totalDesember2 = this.datadetail.reduce((a, { Desember2: e }) => a + e, 0);
                                this.totalDesember = this.totalDesember1 + this.totalDesember2;
                                this.rataDesember1 = this.totalDesember1 / Response.data.content.data.length;
                                this.rataDesember2 = this.totalDesember2 / Response.data.content.data.length;
                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Desember1);
                                    this.shortDesember1.push(this.datadetail[i].Desember1);
                                    this.shortDesember1.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    })
                                }
                                this.maxDesember1 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Desember2);
                                    this.shortDesember2.push(this.datadetail[i].Desember2);
                                    this.shortDesember2.sort((a, b) => {
                                        //this.halamanloading = false;
                                        return b - a;
                                    })
                                }
                                this.maxDesember2 = Math.max(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Desember1);
                                }
                                this.minDesember1 = Math.min(...numbers);

                                var numbers = [];
                                for (let i = 0; i < this.datadetail.length; i++) {
                                    numbers.push(this.datadetail[i].Desember2);
                                }
                                this.minDesember2 = Math.min(...numbers);
                                //alert(this.datadetail);



                                for (let i = 0; i < this.datadetail.length; i++) {
                                    var persen = ((i + 1) / (this.datadetail.length + 1) * 100)
                                    this.rata2HujanHarian.push({
                                        "Persen": persen,
                                        "Januari1": this.shortJanuari1[i],
                                        "Januari2": this.shortJanuari2[i],
                                        "Februari1": this.shortFebruari1[i],
                                        "Februari2": this.shortFebruari2[i],
                                        "Maret1": this.shortMaret1[i],
                                        "Maret2": this.shortMaret2[i],
                                        "April1": this.shortApril1[i],
                                        "April2": this.shortApril2[i],
                                        "Mei1": this.shortMei1[i],
                                        "Mei2": this.shortMei2[i],
                                        "Juni1": this.shortJuni1[i],
                                        "Juni2": this.shortJuni2[i],
                                        "Juli1": this.shortJuli1[i],
                                        "Juli2": this.shortJuli2[i],
                                        "Agustus1": this.shortAgustus1[i],
                                        "Agustus2": this.shortAgustus2[i],
                                        "September1": this.shortSeptember1[i],
                                        "September2": this.shortSeptember2[i],
                                        "Oktober1": this.shortOktober1[i],
                                        "Oktober2": this.shortOktober2[i],
                                        "November1": this.shortNovember1[i],
                                        "November2": this.shortNovember2[i],
                                        "Desember1": this.shortDesember1[i],
                                        "Desember2": this.shortDesember2[i],
                                    })

                                    this.rata2HujanHarianPerHari.push({
                                        "Januari1": this.rataJanuari1 / 15,
                                        "Januari2": this.rataJanuari2 / 16,
                                        "Februari1": this.rataFebruari1 / 14,
                                        "Februari2": this.rataFebruari2 / 14,
                                        "Maret1": this.rataMaret1 / 15,
                                        "Maret2": this.rataMaret2 / 16,
                                        "April1": this.rataApril1 / 15,
                                        "April2": this.rataApril2 / 15,
                                        "Mei1": this.rataMei1 / 15,
                                        "Mei2": this.rataMei2 / 16,
                                        "Juni1": this.rataJuni1 / 15,
                                        "Juni2": this.rataJuni2 / 15,
                                        "Juli1": this.rataJuli1 / 15,
                                        "Juli2": this.rataJuli2 / 16,
                                        "Agustus1": this.rataAgustus1 / 15,
                                        "Agustus2": this.rataAgustus2 / 16,
                                        "September1": this.rataSeptember1 / 15,
                                        "September2": this.rataSeptember2 / 15,
                                        "Oktober1": this.rataOktober1 / 15,
                                        "Oktober2": this.rataOktober2 / 16,
                                        "November1": this.rataNovember1 / 15,
                                        "November2": this.rataNovember2 / 15,
                                        "Desember1": this.rataDesember1 / 15,
                                        "Desember2": this.rataDesember2 / 16,
                                    })

                                    this.rata2HujanHarianPerHariGrafik = [
                                        this.rataJanuari1 / 15,
                                        this.rataJanuari2 / 16,
                                        this.rataFebruari1 / 14,
                                        this.rataFebruari2 / 14,
                                        this.rataMaret1 / 15,
                                        this.rataMaret2 / 16,
                                        this.rataApril1 / 15,
                                        this.rataApril2 / 15,
                                        this.rataMei1 / 15,
                                        this.rataMei2 / 16,
                                        this.rataJuni1 / 15,
                                        this.rataJuni2 / 15,
                                        this.rataJuli1 / 15,
                                        this.rataJuli2 / 16,
                                        this.rataAgustus1 / 15,
                                        this.rataAgustus2 / 16,
                                        this.rataSeptember1 / 15,
                                        this.rataSeptember2 / 15,
                                        this.rataOktober1 / 15,
                                        this.rataOktober2 / 16,
                                        this.rataNovember1 / 15,
                                        this.rataNovember2 / 15,
                                        this.rataDesember1 / 15,
                                        this.rataDesember2 / 16
                                    ];
                                }

                                this.Jumlahdatarata2HujanHarian = this.rata2HujanHarian.length;

                                var found80 = [];
                                found80 = this.rata2HujanHarian.filter((element) => (element.Persen > 70 && element.Persen < 90));
                                //alert(JSON.stringify(found80));
                                var januari1persen = [];
                                var januari1data = [];
                                var januari2data = [];
                                var februari1data = [];
                                var februari2data = [];
                                var maret1data = [];
                                var maret2data = [];
                                var april1data = [];
                                var april2data = [];
                                var mei1data = [];
                                var mei2data = [];
                                var juni1data = [];
                                var juni2data = [];
                                var juli1data = [];
                                var juli2data = [];
                                var agustus1data = [];
                                var agustus2data = [];
                                var september1data = [];
                                var september2data = [];
                                var oktober1data = [];
                                var oktober2data = [];
                                var november1data = [];
                                var november2data = [];
                                var desember1data = [];
                                var desember2data = [];

                                for (let i = 0; i < found80.length; i++) {
                                    januari1persen.push(parseFloat(found80[i].Persen.toFixed(2)));

                                    januari1data.push(parseFloat(found80[i].Januari1.toFixed(2)));
                                    januari2data.push(parseFloat(found80[i].Januari2.toFixed(2)));

                                    februari1data.push(parseFloat(found80[i].Februari1.toFixed(2)));
                                    februari2data.push(parseFloat(found80[i].Februari2.toFixed(2)));

                                    maret1data.push(parseFloat(found80[i].Maret1.toFixed(2)));
                                    maret2data.push(parseFloat(found80[i].Maret2.toFixed(2)));

                                    april1data.push(parseFloat(found80[i].April1.toFixed(2)));
                                    april2data.push(parseFloat(found80[i].April2.toFixed(2)));

                                    mei1data.push(parseFloat(found80[i].Mei1.toFixed(2)));
                                    mei2data.push(parseFloat(found80[i].Mei2.toFixed(2)));

                                    juni1data.push(parseFloat(found80[i].Juni1.toFixed(2)));
                                    juni2data.push(parseFloat(found80[i].Juni2.toFixed(2)));

                                    juli1data.push(parseFloat(found80[i].Juli1.toFixed(2)));
                                    juli2data.push(parseFloat(found80[i].Juli2.toFixed(2)));

                                    agustus1data.push(parseFloat(found80[i].Agustus1.toFixed(2)));
                                    agustus2data.push(parseFloat(found80[i].Agustus2.toFixed(2)));

                                    september1data.push(parseFloat(found80[i].September1.toFixed(2)));
                                    september2data.push(parseFloat(found80[i].September2.toFixed(2)));

                                    oktober1data.push(parseFloat(found80[i].Oktober1.toFixed(2)));
                                    oktober2data.push(parseFloat(found80[i].Oktober2.toFixed(2)));

                                    november1data.push(parseFloat(found80[i].November1.toFixed(2)));
                                    november2data.push(parseFloat(found80[i].November2.toFixed(2)));

                                    desember1data.push(parseFloat(found80[i].Desember1.toFixed(2)));
                                    desember2data.push(parseFloat(found80[i].Desember2.toFixed(2)));
                                }
                                //alert(JSON.stringify(januari1persen));
                                //alert(JSON.stringify(februari1data));
                                //alert(this.forecast(0.8,[72.7,81.8],[44,23])); 
                                //alert(this.forecast(0.8, januari1persen, januari1data));
                                this.Q80 = [];
                                this.Q80.push({
                                    "Januari1": this.forecast(0.8, januari1persen, januari1data),
                                    "Januari2": this.forecast(0.8, januari1persen, januari2data),
                                    "Februari1": this.forecast(0.8, januari1persen, februari1data),
                                    "Februari2": this.forecast(0.8, januari1persen, februari2data),
                                    "Maret1": this.forecast(0.8, januari1persen, maret1data),
                                    "Maret2": this.forecast(0.8, januari1persen, maret2data),
                                    "April1": this.forecast(0.8, januari1persen, april1data),
                                    "April2": this.forecast(0.8, januari1persen, april2data),
                                    "Mei1": this.forecast(0.8, januari1persen, mei1data),
                                    "Mei2": this.forecast(0.8, januari1persen, mei2data),
                                    "Juni1": this.forecast(0.8, januari1persen, juni1data),
                                    "Juni2": this.forecast(0.8, januari1persen, juni2data),
                                    "Juli1": this.forecast(0.8, januari1persen, juli1data),
                                    "Juli2": this.forecast(0.8, januari1persen, juli2data),
                                    "Agustus1": this.forecast(0.8, januari1persen, agustus1data),
                                    "Agustus2": this.forecast(0.8, januari1persen, agustus2data),
                                    "September1": this.forecast(0.8, januari1persen, september1data),
                                    "September2": this.forecast(0.8, januari1persen, september2data),
                                    "Oktober1": this.forecast(0.8, januari1persen, oktober1data),
                                    "Oktober2": this.forecast(0.8, januari1persen, oktober2data),
                                    "November1": this.forecast(0.8, januari1persen, november1data),
                                    "November2": this.forecast(0.8, januari1persen, november2data),
                                    "Desember1": this.forecast(0.8, januari1persen, desember1data),
                                    "Desember2": this.forecast(0.8, januari1persen, desember2data),
                                });

                                this.RE80 = [];
                                this.RE80.push({
                                    "Januari1": this.forecast(0.8, januari1persen, januari1data) * 0.7,
                                    "Januari2": this.forecast(0.8, januari1persen, januari2data) * 0.7,
                                    "Februari1": this.forecast(0.8, januari1persen, februari1data) * 0.7,
                                    "Februari2": this.forecast(0.8, januari1persen, februari2data) * 0.7,
                                    "Maret1": this.forecast(0.8, januari1persen, maret1data) * 0.7,
                                    "Maret2": this.forecast(0.8, januari1persen, maret2data) * 0.7,
                                    "April1": this.forecast(0.8, januari1persen, april1data) * 0.7,
                                    "April2": this.forecast(0.8, januari1persen, april2data) * 0.7,
                                    "Mei1": this.forecast(0.8, januari1persen, mei1data) * 0.7,
                                    "Mei2": this.forecast(0.8, januari1persen, mei2data) * 0.7,
                                    "Juni1": this.forecast(0.8, januari1persen, juni1data) * 0.7,
                                    "Juni2": this.forecast(0.8, januari1persen, juni2data) * 0.7,
                                    "Juli1": this.forecast(0.8, januari1persen, juli1data) * 0.7,
                                    "Juli2": this.forecast(0.8, januari1persen, juli2data) * 0.7,
                                    "Agustus1": this.forecast(0.8, januari1persen, agustus1data) * 0.7,
                                    "Agustus2": this.forecast(0.8, januari1persen, agustus2data) * 0.7,
                                    "September1": this.forecast(0.8, januari1persen, september1data) * 0.7,
                                    "September2": this.forecast(0.8, januari1persen, september2data) * 0.7,
                                    "Oktober1": this.forecast(0.8, januari1persen, oktober1data) * 0.7,
                                    "Oktober2": this.forecast(0.8, januari1persen, oktober2data) * 0.7,
                                    "November1": this.forecast(0.8, januari1persen, november1data) * 0.7,
                                    "November2": this.forecast(0.8, januari1persen, november2data) * 0.7,
                                    "Desember1": this.forecast(0.8, januari1persen, desember1data) * 0.7,
                                    "Desember2": this.forecast(0.8, januari1persen, desember2data) * 0.7,
                                });

                                this.RE80Hari = [];
                                this.RE80HariGrafik = [];
                                this.RE80Hari.push({
                                    "Januari1": this.forecast(0.8, januari1persen, januari1data) * 0.7 / 15,
                                    "Januari2": this.forecast(0.8, januari1persen, januari2data) * 0.7 / 16,
                                    "Februari1": this.forecast(0.8, januari1persen, februari1data) * 0.7 / 14,
                                    "Februari2": this.forecast(0.8, januari1persen, februari2data) * 0.7 / 14,
                                    "Maret1": this.forecast(0.8, januari1persen, maret1data) * 0.7 / 15,
                                    "Maret2": this.forecast(0.8, januari1persen, maret2data) * 0.7 / 16,
                                    "April1": this.forecast(0.8, januari1persen, april1data) * 0.7 / 15,
                                    "April2": this.forecast(0.8, januari1persen, april2data) * 0.7 / 15,
                                    "Mei1": this.forecast(0.8, januari1persen, mei1data) * 0.7 / 15,
                                    "Mei2": this.forecast(0.8, januari1persen, mei2data) * 0.7 / 16,
                                    "Juni1": this.forecast(0.8, januari1persen, juni1data) * 0.7 / 15,
                                    "Juni2": this.forecast(0.8, januari1persen, juni2data) * 0.7 / 15,
                                    "Juli1": this.forecast(0.8, januari1persen, juli1data) * 0.7 / 15,
                                    "Juli2": this.forecast(0.8, januari1persen, juli2data) * 0.7 / 16,
                                    "Agustus1": this.forecast(0.8, januari1persen, agustus1data) * 0.7 / 15,
                                    "Agustus2": this.forecast(0.8, januari1persen, agustus2data) * 0.7 / 16,
                                    "September1": this.forecast(0.8, januari1persen, september1data) * 0.7 / 15,
                                    "September2": this.forecast(0.8, januari1persen, september2data) * 0.7 / 15,
                                    "Oktober1": this.forecast(0.8, januari1persen, oktober1data) * 0.7 / 15,
                                    "Oktober2": this.forecast(0.8, januari1persen, oktober2data) * 0.7 / 16,
                                    "November1": this.forecast(0.8, januari1persen, november1data) * 0.7 / 15,
                                    "November2": this.forecast(0.8, januari1persen, november2data) * 0.7 / 15,
                                    "Desember1": this.forecast(0.8, januari1persen, desember1data) * 0.7 / 15,
                                    "Desember2": this.forecast(0.8, januari1persen, desember2data) * 0.7 / 16,
                                });

                                this.RE80HariGrafik = [
                                    this.forecast(0.8, januari1persen, januari1data) * 0.7 / 15,
                                    this.forecast(0.8, januari1persen, januari2data) * 0.7 / 16,
                                    this.forecast(0.8, januari1persen, februari1data) * 0.7 / 14,
                                    this.forecast(0.8, januari1persen, februari2data) * 0.7 / 14,
                                    this.forecast(0.8, januari1persen, maret1data) * 0.7 / 15,
                                    this.forecast(0.8, januari1persen, maret2data) * 0.7 / 16,
                                    this.forecast(0.8, januari1persen, april1data) * 0.7 / 15,
                                    this.forecast(0.8, januari1persen, april2data) * 0.7 / 15,
                                    this.forecast(0.8, januari1persen, mei1data) * 0.7 / 15,
                                    this.forecast(0.8, januari1persen, mei2data) * 0.7 / 16,
                                    this.forecast(0.8, januari1persen, juni1data) * 0.7 / 15,
                                    this.forecast(0.8, januari1persen, juni2data) * 0.7 / 15,
                                    this.forecast(0.8, januari1persen, juli1data) * 0.7 / 15,
                                    this.forecast(0.8, januari1persen, juli2data) * 0.7 / 16,
                                    this.forecast(0.8, januari1persen, agustus1data) * 0.7 / 15,
                                    this.forecast(0.8, januari1persen, agustus2data) * 0.7 / 16,
                                    this.forecast(0.8, januari1persen, september1data) * 0.7 / 15,
                                    this.forecast(0.8, januari1persen, september2data) * 0.7 / 15,
                                    this.forecast(0.8, januari1persen, oktober1data) * 0.7 / 15,
                                    this.forecast(0.8, januari1persen, oktober2data) * 0.7 / 16,
                                    this.forecast(0.8, januari1persen, november1data) * 0.7 / 15,
                                    this.forecast(0.8, januari1persen, november2data) * 0.7 / 15,
                                    this.forecast(0.8, januari1persen, desember1data) * 0.7 / 15,
                                    this.forecast(0.8, januari1persen, desember2data) * 0.7 / 16
                                ];

                                var found50 = [];
                                found50 = this.rata2HujanHarian.filter((element) => (element.Persen > 40 && element.Persen < 60));
                                //alert(JSON.stringify(found80));
                                var januari1persen = [];
                                var januari1data = [];
                                var januari2data = [];
                                var februari1data = [];
                                var februari2data = [];
                                var maret1data = [];
                                var maret2data = [];
                                var april1data = [];
                                var april2data = [];
                                var mei1data = [];
                                var mei2data = [];
                                var juni1data = [];
                                var juni2data = [];
                                var juli1data = [];
                                var juli2data = [];
                                var agustus1data = [];
                                var agustus2data = [];
                                var september1data = [];
                                var september2data = [];
                                var oktober1data = [];
                                var oktober2data = [];
                                var november1data = [];
                                var november2data = [];
                                var desember1data = [];
                                var desember2data = [];
                                for (let i = 0; i < found50.length; i++) {
                                    januari1persen.push(parseFloat(found50[i].Persen.toFixed(2)));

                                    januari1data.push(parseFloat(found50[i].Januari1.toFixed(2)));
                                    januari2data.push(parseFloat(found50[i].Januari2.toFixed(2)));

                                    februari1data.push(parseFloat(found50[i].Februari1.toFixed(2)));
                                    februari2data.push(parseFloat(found50[i].Februari2.toFixed(2)));

                                    maret1data.push(parseFloat(found50[i].Maret1.toFixed(2)));
                                    maret2data.push(parseFloat(found50[i].Maret2.toFixed(2)));

                                    april1data.push(parseFloat(found50[i].April1.toFixed(2)));
                                    april2data.push(parseFloat(found50[i].April2.toFixed(2)));

                                    mei1data.push(parseFloat(found50[i].Mei1.toFixed(2)));
                                    mei2data.push(parseFloat(found50[i].Mei2.toFixed(2)));

                                    juni1data.push(parseFloat(found50[i].Juni1.toFixed(2)));
                                    juni2data.push(parseFloat(found50[i].Juni2.toFixed(2)));

                                    juli1data.push(parseFloat(found50[i].Juli1.toFixed(2)));
                                    juli2data.push(parseFloat(found50[i].Juli2.toFixed(2)));

                                    agustus1data.push(parseFloat(found50[i].Agustus1.toFixed(2)));
                                    agustus2data.push(parseFloat(found50[i].Agustus2.toFixed(2)));

                                    september1data.push(parseFloat(found50[i].September1.toFixed(2)));
                                    september2data.push(parseFloat(found50[i].September2.toFixed(2)));

                                    oktober1data.push(parseFloat(found50[i].Oktober1.toFixed(2)));
                                    oktober2data.push(parseFloat(found50[i].Oktober2.toFixed(2)));

                                    november1data.push(parseFloat(found50[i].November1.toFixed(2)));
                                    november2data.push(parseFloat(found50[i].November2.toFixed(2)));

                                    desember1data.push(parseFloat(found50[i].Desember1.toFixed(2)));
                                    desember2data.push(parseFloat(found50[i].Desember2.toFixed(2)));
                                }

                                this.Q50 = [];
                                this.Q50.push({
                                    "Januari1": this.forecast(0.5, januari1persen, januari1data),
                                    "Januari2": this.forecast(0.5, januari1persen, januari2data),
                                    "Februari1": this.forecast(0.5, januari1persen, februari1data),
                                    "Februari2": this.forecast(0.5, januari1persen, februari2data),
                                    "Maret1": this.forecast(0.5, januari1persen, maret1data),
                                    "Maret2": this.forecast(0.5, januari1persen, maret2data),
                                    "April1": this.forecast(0.5, januari1persen, april1data),
                                    "April2": this.forecast(0.5, januari1persen, april2data),
                                    "Mei1": this.forecast(0.5, januari1persen, mei1data),
                                    "Mei2": this.forecast(0.5, januari1persen, mei2data),
                                    "Juni1": this.forecast(0.5, januari1persen, juni1data),
                                    "Juni2": this.forecast(0.5, januari1persen, juni2data),
                                    "Juli1": this.forecast(0.5, januari1persen, juli1data),
                                    "Juli2": this.forecast(0.5, januari1persen, juli2data),
                                    "Agustus1": this.forecast(0.5, januari1persen, agustus1data),
                                    "Agustus2": this.forecast(0.5, januari1persen, agustus2data),
                                    "September1": this.forecast(0.5, januari1persen, september1data),
                                    "September2": this.forecast(0.5, januari1persen, september2data),
                                    "Oktober1": this.forecast(0.5, januari1persen, oktober1data),
                                    "Oktober2": this.forecast(0.5, januari1persen, oktober2data),
                                    "November1": this.forecast(0.5, januari1persen, november1data),
                                    "November2": this.forecast(0.5, januari1persen, november2data),
                                    "Desember1": this.forecast(0.5, januari1persen, desember1data),
                                    "Desember2": this.forecast(0.5, januari1persen, desember2data),
                                });

                                this.RE50 = [];
                                this.RE50.push({
                                    "Januari1": this.forecast(0.5, januari1persen, januari1data) * 0.7,
                                    "Januari2": this.forecast(0.5, januari1persen, januari2data) * 0.7,
                                    "Februari1": this.forecast(0.5, januari1persen, februari1data) * 0.7,
                                    "Februari2": this.forecast(0.5, januari1persen, februari2data) * 0.7,
                                    "Maret1": this.forecast(0.5, januari1persen, maret1data) * 0.7,
                                    "Maret2": this.forecast(0.5, januari1persen, maret2data) * 0.7,
                                    "April1": this.forecast(0.5, januari1persen, april1data) * 0.7,
                                    "April2": this.forecast(0.5, januari1persen, april2data) * 0.7,
                                    "Mei1": this.forecast(0.5, januari1persen, mei1data) * 0.7,
                                    "Mei2": this.forecast(0.5, januari1persen, mei2data) * 0.7,
                                    "Juni1": this.forecast(0.5, januari1persen, juni1data) * 0.7,
                                    "Juni2": this.forecast(0.5, januari1persen, juni2data) * 0.7,
                                    "Juli1": this.forecast(0.5, januari1persen, juli1data) * 0.7,
                                    "Juli2": this.forecast(0.5, januari1persen, juli2data) * 0.7,
                                    "Agustus1": this.forecast(0.5, januari1persen, agustus1data) * 0.7,
                                    "Agustus2": this.forecast(0.5, januari1persen, agustus2data) * 0.7,
                                    "September1": this.forecast(0.5, januari1persen, september1data) * 0.7,
                                    "September2": this.forecast(0.5, januari1persen, september2data) * 0.7,
                                    "Oktober1": this.forecast(0.5, januari1persen, oktober1data) * 0.7,
                                    "Oktober2": this.forecast(0.5, januari1persen, oktober2data) * 0.7,
                                    "November1": this.forecast(0.5, januari1persen, november1data) * 0.7,
                                    "November2": this.forecast(0.5, januari1persen, november2data) * 0.7,
                                    "Desember1": this.forecast(0.5, januari1persen, desember1data) * 0.7,
                                    "Desember2": this.forecast(0.5, januari1persen, desember2data) * 0.7,
                                });

                                this.RE50Hari = [];
                                this.RE50HariGrafik = [];
                                this.RE50Hari.push({
                                    "Januari1": this.forecast(0.5, januari1persen, januari1data) * 0.7 / 15,
                                    "Januari2": this.forecast(0.5, januari1persen, januari2data) * 0.7 / 16,
                                    "Februari1": this.forecast(0.5, januari1persen, februari1data) * 0.7 / 14,
                                    "Februari2": this.forecast(0.5, januari1persen, februari2data) * 0.7 / 14,
                                    "Maret1": this.forecast(0.5, januari1persen, maret1data) * 0.7 / 15,
                                    "Maret2": this.forecast(0.5, januari1persen, maret2data) * 0.7 / 16,
                                    "April1": this.forecast(0.5, januari1persen, april1data) * 0.7 / 15,
                                    "April2": this.forecast(0.5, januari1persen, april2data) * 0.7 / 15,
                                    "Mei1": this.forecast(0.5, januari1persen, mei1data) * 0.7 / 15,
                                    "Mei2": this.forecast(0.5, januari1persen, mei2data) * 0.7 / 16,
                                    "Juni1": this.forecast(0.5, januari1persen, juni1data) * 0.7 / 15,
                                    "Juni2": this.forecast(0.5, januari1persen, juni2data) * 0.7 / 15,
                                    "Juli1": this.forecast(0.5, januari1persen, juli1data) * 0.7 / 15,
                                    "Juli2": this.forecast(0.5, januari1persen, juli2data) * 0.7 / 16,
                                    "Agustus1": this.forecast(0.5, januari1persen, agustus1data) * 0.7 / 15,
                                    "Agustus2": this.forecast(0.5, januari1persen, agustus2data) * 0.7 / 16,
                                    "September1": this.forecast(0.5, januari1persen, september1data) * 0.7 / 15,
                                    "September2": this.forecast(0.5, januari1persen, september2data) * 0.7 / 15,
                                    "Oktober1": this.forecast(0.5, januari1persen, oktober1data) * 0.7 / 15,
                                    "Oktober2": this.forecast(0.5, januari1persen, oktober2data) * 0.7 / 16,
                                    "November1": this.forecast(0.5, januari1persen, november1data) * 0.7 / 15,
                                    "November2": this.forecast(0.5, januari1persen, november2data) * 0.7 / 15,
                                    "Desember1": this.forecast(0.5, januari1persen, desember1data) * 0.7 / 15,
                                    "Desember2": this.forecast(0.5, januari1persen, desember2data) * 0.7 / 16,
                                });

                                this.RE50HariGrafik = [
                                    this.forecast(0.5, januari1persen, januari1data) * 0.7 / 15,
                                    this.forecast(0.5, januari1persen, januari2data) * 0.7 / 16,
                                    this.forecast(0.5, januari1persen, februari1data) * 0.7 / 14,
                                    this.forecast(0.5, januari1persen, februari2data) * 0.7 / 14,
                                    this.forecast(0.5, januari1persen, maret1data) * 0.7 / 15,
                                    this.forecast(0.5, januari1persen, maret2data) * 0.7 / 16,
                                    this.forecast(0.5, januari1persen, april1data) * 0.7 / 15,
                                    this.forecast(0.5, januari1persen, april2data) * 0.7 / 15,
                                    this.forecast(0.5, januari1persen, mei1data) * 0.7 / 15,
                                    this.forecast(0.5, januari1persen, mei2data) * 0.7 / 16,
                                    this.forecast(0.5, januari1persen, juni1data) * 0.7 / 15,
                                    this.forecast(0.5, januari1persen, juni2data) * 0.7 / 15,
                                    this.forecast(0.5, januari1persen, juli1data) * 0.7 / 15,
                                    this.forecast(0.5, januari1persen, juli2data) * 0.7 / 16,
                                    this.forecast(0.5, januari1persen, agustus1data) * 0.7 / 15,
                                    this.forecast(0.5, januari1persen, agustus2data) * 0.7 / 16,
                                    this.forecast(0.5, januari1persen, september1data) * 0.7 / 15,
                                    this.forecast(0.5, januari1persen, september2data) * 0.7 / 15,
                                    this.forecast(0.5, januari1persen, oktober1data) * 0.7 / 15,
                                    this.forecast(0.5, januari1persen, oktober2data) * 0.7 / 16,
                                    this.forecast(0.5, januari1persen, november1data) * 0.7 / 15,
                                    this.forecast(0.5, januari1persen, november2data) * 0.7 / 15,
                                    this.forecast(0.5, januari1persen, desember1data) * 0.7 / 15,
                                    this.forecast(0.5, januari1persen, desember2data) * 0.7 / 16
                                ];

                                this.series = [
                                    {
                                        name: "Curah Hujan Rata-rata",
                                        data: this.rata2HujanHarianPerHariGrafik
                                    },
                                    {
                                        name: "Curah Hujan Efektif Padi",
                                        data: this.RE80HariGrafik
                                    },
                                    {
                                        name: "Curah Hujan Efektif Palawija",
                                        data: this.RE50HariGrafik
                                    }
                                ];

                                if (document.getElementById("myInfoBawah").style.width == "90vw") {
                                    document.getElementById("myInfoBawah").style.height = "0";
                                } else {
                                    document.getElementById("myInfoBawah").style.width = "100vw";
                                    document.getElementById("myInfoBawah").style.height = "100vh";
                                }
                                this.halamanloading = false;

                            }
                            else {
                                this.dataisi = [];
                                this.datadetail = [];
                                this.dataklimatologi = [];
                                this.dataet0 = [];
                                this.rata2HujanHarian = [];
                                this.Jumlahdatarata2HujanHarian = 0;
                                this.Q80 = [];
                                this.Q50 = [];
                                this.RE80 = [];
                                this.RE80Hari = [];
                                this.RE80HariGrafik = [];
                                this.RE50 = [];
                                this.RE50Hari = [];
                                this.RE50HariGrafik = [];
                                this.shortJanuari1 = [];
                                this.shortJanuari2 = [];
                                this.shortFebruari1 = [];
                                this.shortFebruari2 = [];
                                this.shortMaret1 = [];
                                this.shortMaret2 = [];
                                this.shortApril1 = [];
                                this.shortApril2 = [];
                                this.shortMei1 = [];
                                this.shortMei2 = [];
                                this.shortJuni1 = [];
                                this.shortJuni2 = [];
                                this.shortJuli1 = [];
                                this.shortJuli2 = [];
                                this.shortAgustus1 = [];
                                this.shortAgustus2 = [];
                                this.shortSeptember1 = [];
                                this.shortSeptember2 = [];
                                this.shortOktober1 = [];
                                this.shortOktober2 = [];
                                this.shortNovember1 = [];
                                this.shortNovember2 = [];
                                this.shortDesember1 = [];
                                this.shortDesember2 = [];
                                this.rata2HujanHarianPerHari = [];
                                this.rata2HujanHarianPerHariGrafik = [];
                                this.series = [];
                                this.tinggi = [];
                                this.koreksi = [];
                                this.ukmhr = [];
                                this.fu = [];
                                this.suhu = [];
                                this.ea = [];
                                this.w = [];
                                this.satuminw = [];
                                this.ft = [];
                                this.acuanra = [];
                                this.ra1 = [];
                                this.ra2 = [];
                                this.ra = [];
                                swal.mixin({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000
                                }).fire({
                                    icon: 'warning',
                                    title: 'Data Tidak Diketemukan..',
                                });
                                $('.swal2-container').css("z-index", '999999');
                                this.halamanloading = false;
                                return false;
                            }

                        }
                    ).catch(error => {
                        this.dataisi = [];
                        this.datadetail = [];
                        this.dataklimatologi = [];
                        this.dataet0 = [];
                        this.rata2HujanHarian = [];
                        this.Jumlahdatarata2HujanHarian = 0;
                        this.Q80 = [];
                        this.Q50 = [];
                        this.RE80 = [];
                        this.RE80Hari = [];
                        this.RE80HariGrafik = [];
                        this.RE50 = [];
                        this.RE50Hari = [];
                        this.RE50HariGrafik = [];
                        this.shortJanuari1 = [];
                        this.shortJanuari2 = [];
                        this.shortFebruari1 = [];
                        this.shortFebruari2 = [];
                        this.shortMaret1 = [];
                        this.shortMaret2 = [];
                        this.shortApril1 = [];
                        this.shortApril2 = [];
                        this.shortMei1 = [];
                        this.shortMei2 = [];
                        this.shortJuni1 = [];
                        this.shortJuni2 = [];
                        this.shortJuli1 = [];
                        this.shortJuli2 = [];
                        this.shortAgustus1 = [];
                        this.shortAgustus2 = [];
                        this.shortSeptember1 = [];
                        this.shortSeptember2 = [];
                        this.shortOktober1 = [];
                        this.shortOktober2 = [];
                        this.shortNovember1 = [];
                        this.shortNovember2 = [];
                        this.shortDesember1 = [];
                        this.shortDesember2 = [];
                        this.rata2HujanHarianPerHari = [];
                        this.rata2HujanHarianPerHariGrafik = [];
                        this.series = [];
                        this.tinggi = [];
                        this.koreksi = [];
                        this.ukmhr = [];
                        this.fu = [];
                        this.suhu = [];
                        this.ea = [];
                        this.w = [];
                        this.satuminw = [];
                        this.ft = [];
                        this.acuanra = [];
                        this.ra1 = [];
                        this.ra2 = [];
                        this.ra = [];
                        console.log(error);
                        //this.ClearlocalStorage();
                        this.halamanloading = false;
                    });
                }

            } catch (error) {

            }
        },
        closeNavInfoBawah() {
            document.getElementById("myInfoBawah").style.height = "0";
            document.getElementById("myInfoBawah").style.width = "0";
        },
        loadpeta() {
            this.halamanloading = true;
            const message = this.linknya + '|' + this.secretencData;
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/map.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },

        datakosong() {
            swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            }).fire({
                icon: 'warning',
                title: 'Data Layer Gagal Diunduh..',
            });
            $('.swal2-container').css("z-index", '999999');
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            this.halamanloading = true;
            const mainAPIGIS = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPIGIS.get("parastapainnovation-Getdatagis?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datapeta = [];
                        this.datapeta = Response.data.content.data;
                    }
                    else {
                        this.datapeta = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datapeta = [];
                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
        this.ambildata();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
}
</script>

<style></style>